//Libs:
import { ILogger } from 'js-logger';
import { AdBreaksParsingType } from './consts/adBreaks';
import { PlaybackContentType } from './consts/contentType';
import { KeySystem } from './consts/drm';
import { isWebOs, isWebTv, OS } from './consts/os';
import {
    BrowserIsNotSupportedPlayerErrorEvent,
    EmptyManifestUrlErrorEvent,
    ErrorAnalyticsData,
    ExternalPlayerErrorEvent,
    LoadFailedPlayerErrorEvent, PlayerAdErrorEvent, UnLoadFailedPlayerErrorEvent
} from './events/error';
import { PlayerEventType } from './events/eventTypes';
//Events:
import {
    PictureInPictureActiveStateEvent,
    PictureInPictureInactiveStateEvent,
    PictureInPictureUnavailableStateEvent
} from './events/pip';
import {
    PlayerEventTarget
} from './events/playerEvent';
import {
    BufferingStatePlayerEvent,
    PausedStatePlayerEvent,
    PlayingStatePlayerEvent,
    StoppedStatePlayerEvent
} from './events/state';
import {
    PlayerAudioTrack,
    PlayerAudioTracksUpdateEvent,
    PlayerTextTrack,
    PlayerTextTracksUpdateEvent
} from './events/tracks';
import {
    PlayerAdBreaksUpdateEvent,
    PlayerBandwidthUpdateEvent,
    PlayerBufferLengthUpdateEvent,
    PlayerDiagnosticInfoUpdateEvent,
    PlayerDurationUpdateEvent,
    PlayerFrameRateUpdateEvent,
    PlayerLogUpdateEvent,
    PlayerManifestResponseHeadersUpdate,
    PlayerManifestTypeUpdateEvent,
    PlayerPositionUpdateEvent,
    PlayerRecoveringEvent,
    PlayerReviewBufferUpdateEvent,
    PlayerScteSegmentsUpdateEvent,
    PlayerSeekUpdateEvent,
    PlayerVolumeUpdateEvent,
} from './events/video';
import {
    ExternalPlayerAdErrorEvent,
    ExternalPlayerEventType
} from './externalPlayer/externalPlayerEvent';
//External Player:
import ExternalPlayer, { ExternalPlayerErrorEvent as ExternalErrorEvent } from './externalPlayer/index';
import PlayerLogger from './logger/logger';
//Reactive Value:
import ReactiveValue from './reactiveValue/reactiveValue';
import { ReactiveValueEventType } from './reactiveValue/reactiveValueEvent';
import { PlayerInfo } from './shared';
import { getScriptByAttributeSelector } from './utils/dom';
import { percentageRatio } from './utils/math';
// Style utils:
import Bowser from 'bowser';
import { applyStyles } from './utils/style';

const CONSIDER_AS_SUCCESSFUL_PLAYBACK = 99;
const WEB_TV_FORCE_CALL_PLAY_TIMER = 6000;

interface PlayerReactiveValues {
    readonly bandwidthValue: ReactiveValue<number>;
    readonly bufferLength: ReactiveValue<number>;
    readonly shouldForceHttpsStreaming: ReactiveValue<boolean>;
    readonly subtitleFontVOD: ReactiveValue<string>;
    readonly subtitleBackgroundVOD: ReactiveValue<string>;
    readonly audioDescriptionLanguages: ReactiveValue<string[]>;
    readonly hardOfHearingSubtitleLanguages: ReactiveValue<string[]>;
    readonly isLive: ReactiveValue<boolean>;
    readonly isVod: ReactiveValue<boolean>;
    readonly diagnosticInfo: ReactiveValue<string>;
}

interface PlayerConfiguration {
    readonly externalPlayer: ExternalPlayer;
    readonly videoElement: HTMLVideoElement | null;
    readonly videoContainer: HTMLDivElement;
    readonly reactiveValues: PlayerReactiveValues;
    readonly playerLogger: PlayerLogger;
    readonly initialPlayerInfo: PlayerInfo;
    readonly isAdPlayer: boolean;
    readonly targetOs: string;
    readonly forceVideoElementCreationOnLoad: boolean;
}

interface PlayerExternalConfiguration {
    readonly shouldForceHttpsStreaming?: boolean;
    readonly subtitleFontVOD?: string;
    readonly subtitleBackgroundVOD?: string;
    readonly isVod: boolean;
    readonly ignoreScte: number[];
    readonly audioDescriptionLanguages: string[];
    readonly hardOfHearingSubtitleLanguages: string[];
    readonly contentType: PlaybackContentType;
    readonly isLive: boolean;
    readonly adBreakType: AdBreaksParsingType;
    readonly licensePersistencyEnabled: boolean;
    readonly shouldParsePreRoll: boolean;
    readonly renewLicenseBeforeExpiration?: number;
    readonly isViperEnabled?: boolean;
    readonly keySystemPriority: KeySystem[];
    readonly maxResolutionWidth: number;
    readonly maxResolutionHeight: number;
    readonly softFrameRate: number;
    readonly hardFrameRate: number;
    readonly forceKeySystem?: KeySystem;
    readonly drmContentId: string;
    readonly isDebugOverlayEnabled: boolean;
    readonly startOverUpdateInterval: number;
    readonly preferredAudioLang: string;
    readonly preferredAudioRole: string;
}

interface PlayerLoadInfo {
    readonly url: string;
    readonly startTime?: number;
    readonly manifest?: string;
    readonly mediaPlaylist?: string;
    readonly shouldAutoPlay: boolean;
}

interface PlayerRestartInfo {
    readonly shouldAutoPlay: boolean;
}


export default class Player extends PlayerEventTarget<PlayerEventType> {
    private readonly externalPlayer: ExternalPlayer;
    private readonly bandwidthValue: ReactiveValue<number>;
    private readonly bufferLength: ReactiveValue<number>;
    private readonly shouldForceHttpsStreaming: ReactiveValue<boolean>;
    protected readonly subtitleFontVOD: ReactiveValue<string>;
    private readonly subtitleBackgroundVOD: ReactiveValue<string>;
    private readonly audioDescriptionLanguages: ReactiveValue<string[]>;
    private readonly hardOfHearingSubtitleLanguages: ReactiveValue<string[]>;
    private readonly isLive: ReactiveValue<boolean>;
    private readonly isVod: ReactiveValue<boolean>;
    private readonly diagnosticInfo: ReactiveValue<string>;
    private readonly forceVideoElementCreationOnLoad: boolean;

    private readonly playerLogger: ILogger;
    private videoElement: HTMLVideoElement | null;

    private isFontLoaded = false;
    private reviewBuffer: number | null = null;
    private url: string;
    private videoElementHasBeenRecreated = false;
    private manifest: string;
    private shouldAutoPlay: boolean;
    private videoTime: number;
    private isViperEnabled = false;
    private manifestType = '';
    private actualTime = 0;
    private targetOs: string;
    private isSeeking = false;
    private isAdPlayer = false;
    private isUserSeek = false;
    private responseHeader = '';
    private firstPlaybackStateHasBeenDispatched = false;
    private isVideoElementRecreationRequired = false;
    private playIfStartsFromGapTimerId: number | null = null;
    private isDebugOverlayEnabled: boolean;
    private stopPromise: Promise<void> | null = null;

    private videoPauseTime = 0;
    private browser = Bowser.getParser(window.navigator.userAgent).getBrowser().name;

    public readonly videoContainer: HTMLDivElement;
    public readonly initialPlayerInfo: PlayerInfo;

    static sharaErrorToErrorCode = new Map<number, number>([
        [6007, 1],
    ]);

    private startTime: number | null = null;

    constructor(playerConfiguration: PlayerConfiguration) {
        super();

        this.externalPlayer = playerConfiguration.externalPlayer;
        this.videoElement = playerConfiguration.videoElement;
        this.videoContainer = playerConfiguration.videoContainer;
        this.bandwidthValue = playerConfiguration.reactiveValues.bandwidthValue;
        this.bufferLength = playerConfiguration.reactiveValues.bufferLength;
        this.shouldForceHttpsStreaming = playerConfiguration.reactiveValues.shouldForceHttpsStreaming;
        this.subtitleFontVOD = playerConfiguration.reactiveValues.subtitleFontVOD;
        this.subtitleBackgroundVOD = playerConfiguration.reactiveValues.subtitleBackgroundVOD;
        this.audioDescriptionLanguages = playerConfiguration.reactiveValues.audioDescriptionLanguages;
        this.hardOfHearingSubtitleLanguages = playerConfiguration.reactiveValues.hardOfHearingSubtitleLanguages;
        this.isLive = playerConfiguration.reactiveValues.isLive;
        this.isVod = playerConfiguration.reactiveValues.isVod;
        this.playerLogger = playerConfiguration.playerLogger.forName(playerConfiguration.isAdPlayer ? 'Player.Ads' : 'Player.Main');
        this.initialPlayerInfo = playerConfiguration.initialPlayerInfo;
        this.targetOs = playerConfiguration.targetOs;
        this.isAdPlayer = playerConfiguration.isAdPlayer;
        this.diagnosticInfo = playerConfiguration.reactiveValues.diagnosticInfo;
        this.isDebugOverlayEnabled = false;
        this.forceVideoElementCreationOnLoad = playerConfiguration.forceVideoElementCreationOnLoad;

        this.url = '';
        this.manifest = '';
        this.shouldAutoPlay = true;
        this.videoTime = 0;
        this.stopPromise = null;


        this.registerVideoElementEvents();
        this.registerExternalPlayerEvents();
        this.registerReactiveValueEvents();
        this.registerPlatformEvents();

        this.playerLogger.debug('is constructed.');
    }

    public get playerInfo(): PlayerInfo {
        return {
            ...this.initialPlayerInfo,
            securityLevel: this.externalPlayer.securityLevel,
        };
    }

    public get droppedFrames(): number {
        return this.externalPlayer.getStats().droppedFrames;
    }

    private get isBuffering(): boolean {
        return this.externalPlayer.isBuffering;
    }

    private get duration(): number {
        return this.isLive.currentValue
            ? this.externalPlayer.reviewBuffer : this.externalPlayer.duration;
    }

    private registerReactiveValueEvents(): void {
        this.bandwidthValue.addEventListener(ReactiveValueEventType.Change, this.onBandwidthUpdate);
        this.bufferLength.addEventListener(ReactiveValueEventType.Change, this.onBufferLengthUpdate);
        this.diagnosticInfo.addEventListener(ReactiveValueEventType.Change, this.onDiagnosticInfoUpdate);
        this.playerLogger.debug('reactive value events are registered');
    }

    private registerVideoElementEvents(): void {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.oncanplay = this.onCanPlay;
        this.videoElement.onloadeddata = this.onLoadedData;
        this.videoElement.onloadedmetadata = this.onLoadedMetadata;
        this.videoElement.ontimeupdate = this.onVideoTimeUpdate;
        this.videoElement.onvolumechange = this.onVolumeUpdate;
        this.videoElement.ondurationchange = this.onDurationUpdate;
        this.videoElement.onplay = this.onPlay;
        this.videoElement.onpause = this.onPause;
        this.videoElement.onended = this.onEnded;
        this.videoElement.onseeking = this.onSeekStart;
        this.videoElement.onseeked = this.onSeekEnd;
        this.videoElement.onenterpictureinpicture = this.onEnterPictureInPicture;
        this.videoElement.onleavepictureinpicture = this.onLeavePictureInPicture;
        this.playerLogger.debug('video element events are registered');
    }

    private registerExternalPlayerEvents(): void {
        this.externalPlayer.addEventListener(ExternalPlayerEventType.Buffering, this.onExternalPlayerBuffering);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.TextChanged, this.onTextTracksUpdate);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.ManifestParsed, this.onManifestParsedEvent);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.VariantChanged, this.onVariantsUpdate);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.TrackChanged, this.onTrackChanged);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.Error, this.onExternalPlayerError);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.RecoveryError, this.onExternalRecoveryError);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.ManifestTypeChanged, this.onManifestTypeUpdate);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.Loaded, this.onExternalPlayerLoad);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.AdError, this.onExternalPlayerAdError);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.LicenseRenewalNeeded, this.onLicenseRenewalNeeded);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.DurationChanged, this.onDurationUpdate);
        this.externalPlayer.addEventListener(ExternalPlayerEventType.ManifestResponseHeadersUpdate, this.onResponseHeaderUpdate);
        this.playerLogger.debug('external player events are registered');
    }

    private registerPlatformEvents(): void {
        if (this.targetOs === OS.tizen) {
            window.addEventListener('visibilitychange', this.onVisibilityChanged);
        }
    }

    private readonly isVideoMuted = (): boolean => {
        if (!this.videoElement) {
            return false;
        }

        const { muted, volume } = this.videoElement;

        return muted || volume === 0;
    };

    private readonly isEventAboutToEnd = (): boolean => {
        return percentageRatio(this.videoTime, this.duration) > CONSIDER_AS_SUCCESSFUL_PLAYBACK;
    };

    private readonly getErrorAnalyticsData = (): ErrorAnalyticsData => {
        const isMuted = this.isVideoMuted();
        const isPageVisible = !document.hidden;

        return new ErrorAnalyticsData(isMuted, isPageVisible);
    };

    private readonly onCanPlay = (): void => {
        this.playerLogger.debug('event::onCanPlay', this.videoElement?.currentTime);
        this.handleStartPosition();

        //On WebOS playback with bookmark onPlay is executed earlier than actual playback starts
        //So need to dispatch onPlay event if it has not been dispatched
        if (isWebOs(this.targetOs) && !this.firstPlaybackStateHasBeenDispatched) {
            this.onPlaybackReadyState();
        }
    };

    private readonly stopPlayGapTimer = (): void => {
        if (this.playIfStartsFromGapTimerId != null) {
            clearTimeout(this.playIfStartsFromGapTimerId);
            this.playIfStartsFromGapTimerId = null;
        }
    };

    private readonly onLoadedData = (): void => {
        this.stopPlayGapTimer();

        this.playerLogger.debug('event::onLoadedData');
        this.onTextTracksUpdate();
        this.onVariantsUpdate();
        this.onVolumeUpdate();
        this.onDurationUpdate();
        this.externalPlayer.onLoadedData();

        this.onPlaybackReadyState();
        this.onResponseHeaderUpdate();
    };

    // Root cause is https://jira.lgi.io/browse/FLUXUI-38459
    // On Tizen MediaElements returns wrong playback ready status
    // if program starts from gap larger than 1 second
    // so we try to force play this program for web tv
    private readonly onLoadedMetadata = (): void => {
        if (!isWebTv(this.targetOs)) {
            return;
        }

        this.stopPlayGapTimer();

        this.playIfStartsFromGapTimerId = window.setTimeout(() => {
            const bufferedStartTime = this.getBufferStart();

            if (bufferedStartTime != null && this.videoElement) {
                this.videoElement.currentTime = bufferedStartTime;
            }
        }, WEB_TV_FORCE_CALL_PLAY_TIMER);
    };

    private readonly getBufferStart = (): number | null => {
        if (!this.videoElement) {
            return null;
        }

        return this.videoElement.buffered.length > 0 ?
            this.videoElement.buffered.start(0) :
            null;
    };

    private readonly onEnded = (): void => {
        const stoppedStateEvent = new StoppedStatePlayerEvent();

        this.playerLogger.debug('event::onEnded', stoppedStateEvent);

        this.dispatchEvent(stoppedStateEvent);
    };

    private readonly onSeekStart = (): void => {
        if (this.isSeeking) {
            return;
        }

        this.isSeeking = true;

        if (this.isUserSeek) {
            const seekUpdateEvent = new PlayerSeekUpdateEvent(true);

            this.playerLogger.debug('event::onSeekStart', seekUpdateEvent);

            this.dispatchEvent(seekUpdateEvent);
        }
    };

    private readonly onSeekEnd = (): void => {
        if (!this.isSeeking) {
            return;
        }

        this.handleCanPlayEvent();

        this.isSeeking = false;

        if (this.isUserSeek) {
            const seekUpdateEvent = new PlayerSeekUpdateEvent(false);

            this.playerLogger.debug('event::onSeekEnd', seekUpdateEvent);

            this.dispatchEvent(seekUpdateEvent);
        }

        this.isUserSeek = false;
    };

    // There is an open Shaka player issue that stream is stalled after seek in Safari
    // https://github.com/shaka-project/shaka-player/issues/3367 - Safari rapid seek hangs stream
    // The root cause of this behavior is that shaka player sets playbackRate=0 when it detect
    // that the playback is buffering.
    // This issue is fixed with forcing playback rate to 1 on canplaythrough event after seek end.
    // This workaround can be removed after shaka player issue #3367 is resolved.
    private handleCanPlayEvent(): void {
        if (!this.videoElement || !this.externalPlayer.shouldWaitForCanPlayEventAfterSeek()) {
            return;
        }

        this.videoElement.addEventListener(
            'canplaythrough',
            () => this.onCanPlayThroughAfterSeek(),
            { once: true }
        );
    }

    // This handler is only used for Safari.
    // Please see the comment with more details above handleCanPlayEvent().
    private onCanPlayThroughAfterSeek() {
        this.playerLogger.debug('onCanPlayThroughAfterSeek has been called');

        if (this.videoElement) {
            this.videoElement.playbackRate = 1;
        }

        this.play();
    }

    private readonly onEnterPictureInPicture = (): void => {
        const pipActiveStateEvent = new PictureInPictureActiveStateEvent();

        this.playerLogger.debug('event::onEnterPictureInPicture', pipActiveStateEvent);

        this.dispatchEvent(pipActiveStateEvent);
    };

    private readonly onLeavePictureInPicture = (): void => {
        const pipInactiveStateEvent = new PictureInPictureInactiveStateEvent();

        this.playerLogger.debug('event::onLeavePictureInPicture', pipInactiveStateEvent);

        let timeout = setTimeout(() => this.playAfterDelay(), 300);
        this.playerLogger.debug('event:: timeout after pip exit', timeout);
        this.dispatchEvent(pipInactiveStateEvent);
    };

    // Delayed play after PIP exit, to avoid the issue with the video not playing after PIP exit
    playAfterDelay() {
        this.play();
    }

    private readonly onExternalRecoveryError = (): void => {
        if (this.isLive.currentValue) {
            return;
        }

        if (this.isEventAboutToEnd()) {
            void this.onEnded();

            return;
        }


        const recoveringEvent = new PlayerRecoveringEvent(true);

        this.playerLogger.error('event::onExternalRecoveryError', recoveringEvent);

        this.dispatchEvent(recoveringEvent);

        void this.restart({ shouldAutoPlay: !this.externalPlayer.isPaused });
    };

    private readonly onExternalPlayerAdError = (event: ExternalPlayerAdErrorEvent): void => {
        const adErrorEvent = new PlayerAdErrorEvent(event.code, event.manifestUrl);

        this.dispatchEvent(adErrorEvent);
    };

    private readonly onExternalPlayerError = (event: ExternalErrorEvent): void => {
        this.stopPlayGapTimer();

        let data = event.data;

        if (data) {
            data = JSON.stringify(event.data);
        }

        const mappedCode = Player.sharaErrorToErrorCode.get(event.code) || event.code;
        const analyticsData = this.getErrorAnalyticsData();

        const externalPlayerErrorEvent = new ExternalPlayerErrorEvent(
            mappedCode,
            data,
            event.name,
            analyticsData,
            event.httpCode,
            event.isStreamingError,
        );

        this.playerLogger.error('event::onExternalPlayerError', externalPlayerErrorEvent);

        this.dispatchEvent(externalPlayerErrorEvent);

        void this.stop();
    };

    private readonly isVideoElementConnected = (): boolean => {
        if (!this.videoElement) {
            return false;
        }

        /**
         * This is a workaround to ignore internal pause events happening during media element removal from DOM.
         * For example: when we switch between the channels video container is reattached to the DOM tree
         * which triggers redundant pause player event (this behaviour, in turn, can cause CIRR increase).
         *
         * @see https://stackoverflow.com/questions/50937026/why-does-a-video-stop-playing-when-removed-from-the-dom-while-it-can-still-pl
         * @see https://html.spec.whatwg.org/multipage/infrastructure.html#remove-an-element-from-a-document
        */
        return this.videoElement.isConnected;
    };

    private readonly onPause = (): void => {
        if (this.isBuffering || !this.videoHasPlaybackData() || !this.isVideoElementConnected()) {
            return;
        }

        const pausedStateEvent = new PausedStatePlayerEvent();

        this.playerLogger.debug('event::onPause', pausedStateEvent);

        this.firstPlaybackStateHasBeenDispatched = true;

        this.dispatchEvent(pausedStateEvent);
    };

    private readonly onPlay = (): void => {
        if (this.isBuffering || !this.videoHasPlaybackData()) {
            return;
        }
        const playingStateEvent = new PlayingStatePlayerEvent();

        this.playerLogger.debug('event::onPlay', playingStateEvent);

        this.firstPlaybackStateHasBeenDispatched = true;

        this.dispatchEvent(playingStateEvent);
    };

    private readonly onPlaybackReadyState = (): void => {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.paused ? this.onPause() : this.onPlay();
    };

    /**
     * Browsers can emit a playing event before actual playback starts. This method will
     * check if the playback has really started. We need to check both videoElement.readyState
     * and played.length as some browsers (Chrome, Edge) can return readyState = HAVE_METADATA
     * after unpause on dynamic streams.
     */
    private readonly videoHasPlaybackData = (): boolean => {
        if (!this.videoElement) {
            return false;
        }

        return (this.videoElement.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) || this.externalPlayer.hasVideoBeenStarted();
    };

    private readonly onExternalPlayerBuffering = (): void => {
        const { isBuffering } = this;

        this.playerLogger.debug('event::onExternalPlayerBuffering::isBuffering', isBuffering);

        if (isBuffering) {
            this.onBuffering();
        } else {
            this.onPlaybackReadyState();
        }
    };

    private readonly onBuffering = (): void => {
        const bufferingStateEvent = new BufferingStatePlayerEvent();

        this.playerLogger.debug('event::onBuffering', bufferingStateEvent);

        this.dispatchEvent(bufferingStateEvent);
    };

    private readonly onTextTracksUpdate = (): void => {
        const textTracks = this.externalPlayer.getTextTracks(this.hardOfHearingSubtitleLanguages.currentValue) as PlayerTextTrack[];
        const textTracksUpdateEvent = new PlayerTextTracksUpdateEvent(textTracks);

        this.playerLogger.info('event::onTextTracksUpdate', textTracksUpdateEvent);

        this.dispatchEvent(textTracksUpdateEvent);
    };

    private readonly onTrackChanged = (): void => {
        const frameRate = this.externalPlayer.getCurrentFrameRate();

        if (!frameRate) {
            return;
        }

        const frameRateUpdateEvent = new PlayerFrameRateUpdateEvent(frameRate);

        this.playerLogger.info('event::onTrackChanged', frameRateUpdateEvent);

        this.dispatchEvent(frameRateUpdateEvent);
    };

    private readonly onVariantsUpdate = (): void => {
        const audioTracks = this.externalPlayer.getAudioTracks(this.audioDescriptionLanguages.currentValue) as PlayerAudioTrack[];
        const audioTracksUpdateEvent = new PlayerAudioTracksUpdateEvent(audioTracks);

        this.playerLogger.info('event::onVariantsUpdate', audioTracksUpdateEvent);

        this.dispatchEvent(audioTracksUpdateEvent);

        this.onTrackChanged();
    };

    private readonly onManifestParsedEvent = (): void => {
        const adBreaks = this.externalPlayer.getAdBreaks();
        const scteSegments = this.externalPlayer.getScteSegments();
        const scteSegmentsUpdateEvent = new PlayerScteSegmentsUpdateEvent(scteSegments);

        const adBreaksUpdateEvent = new PlayerAdBreaksUpdateEvent(adBreaks);

        this.playerLogger.info('event::adBreaksUpdateEvent', adBreaksUpdateEvent);
        this.playerLogger.info('event::scteSegmentsUpdateEvent', scteSegmentsUpdateEvent);
        this.dispatchEvent(scteSegmentsUpdateEvent);
        this.dispatchEvent(adBreaksUpdateEvent);
        this.onDurationUpdate();
    };

    private readonly onDurationUpdate = (): void => {
        if (isNaN(this.duration)) {
            return;
        }

        const durationUpdateEvent = new PlayerDurationUpdateEvent(this.duration);

        this.playerLogger.debug('event::onDurationUpdate', durationUpdateEvent);

        this.dispatchEvent(durationUpdateEvent);

        this.onManifestTypeUpdate();
    };

    private readonly onLicenseRenewalNeeded = (): void => {
        this.playerLogger.debug('event::onLicenseRenewalNeeded: License renewal is needed. Restarting playback');

        void this.restart({ shouldAutoPlay: !this.externalPlayer.isPaused });
    };

    private get isPlayingAnything(): boolean {
        return !!this.videoElement?.src;
    }

    onManifestTypeUpdate = (): void => {
        if (this.manifestType == this.externalPlayer.manifestType) {
            return;
        }

        this.manifestType = this.externalPlayer.manifestType;

        const manifestUpdateEvent = new PlayerManifestTypeUpdateEvent(this.manifestType);

        this.playerLogger.debug('event::onManifestTypeUpdate', manifestUpdateEvent);

        this.dispatchEvent(manifestUpdateEvent);
    };

    private readonly onVolumeUpdate = (): void => {
        const volumeUpdateEvent = new PlayerVolumeUpdateEvent(this.videoElement?.volume || 0);

        this.playerLogger.debug('event::onVolumeUpdate', volumeUpdateEvent);

        this.dispatchEvent(volumeUpdateEvent);
    };

    private readonly onBandwidthUpdate = (): void => {
        const value = this.bandwidthValue.currentValue;
        const bandwidthUpdateEvent = new PlayerBandwidthUpdateEvent(value);

        this.dispatchEvent(bandwidthUpdateEvent);
    };

    private readonly onBufferLengthUpdate = (): void => {
        const value = this.bufferLength.currentValue;
        const bufferLengthhUpdateEvent = new PlayerBufferLengthUpdateEvent(value);

        this.dispatchEvent(bufferLengthhUpdateEvent);
    };

    private readonly onDiagnosticInfoUpdate = (): void => {
        const value = this.diagnosticInfo.currentValue;
        const diagnosticInfoUpdateEvent = new PlayerDiagnosticInfoUpdateEvent(value);

        this.dispatchEvent(diagnosticInfoUpdateEvent);
    };

    private handleStartPosition(): void {
        if (this.isLive.currentValue) {
            this.externalPlayer.setPlaybackStartState();
            return;
        }
        // hack for hls startover, otherwise startPosition will always be live moment no matter what startPosition is provided in load
        if (this.externalPlayer.shouldHandleStartPosition && this.startTime !== null) {
            this.playerLogger.debug('operation:handleStartPosition');

            this.externalPlayer.setPlaybackStartState();
            this.setPosition(this.startTime, { manual: false });
            this.startTime = null;
        }
    }

    private readonly onVideoTimeUpdate = (): void => {
        if (!this.videoElement) {
            return;
        }

        this.videoTime = this.videoElement.currentTime;

        const isLive = this.isLive.currentValue;
        const seekRange = this.externalPlayer.seekRange;
        const actualTime = isLive ? this.videoTime - seekRange.start : this.videoTime;

        const positionUpdateEvent = new PlayerPositionUpdateEvent(actualTime);
        const reviewBuffer = this.externalPlayer.reviewBuffer;

        const trunkedActualTime = Math.trunc(actualTime);

        if ((!isWebTv(this.targetOs) || this.actualTime != trunkedActualTime) && this.isPlayingAnything) {
            this.actualTime = trunkedActualTime;
            this.playerLogger.trace('event::onVideoTimeUpdate', positionUpdateEvent);
            this.dispatchEvent(positionUpdateEvent);
        }

        if (isLive && this.reviewBuffer !== reviewBuffer) {
            const reviewBufferUpdateEvent = new PlayerReviewBufferUpdateEvent(reviewBuffer);

            this.reviewBuffer = reviewBuffer;
            this.playerLogger.debug('event::onReviewBufferUpdate', reviewBufferUpdateEvent);
            this.dispatchEvent(reviewBufferUpdateEvent);
        }

        this.bandwidthValue.set(this.externalPlayer.getStats().streamBandwidth);
        this.bufferLength.set(this.externalPlayer.getBufferedInfo().bufferLength);

        if (isWebTv(this.targetOs) && this.isDebugOverlayEnabled) {
            this.diagnosticInfo.set(this.externalPlayer.getDiagnosticInfo());
        }
    };

    private readonly onExternalPlayerLoadFailed = (): void => {
        if (!this.externalPlayer.isBrowserSupported) {
            return this.dispatchEvent(new BrowserIsNotSupportedPlayerErrorEvent());
        }

        const loadFailedEvent = new LoadFailedPlayerErrorEvent();

        this.playerLogger.error('event::onExternalPlayerLoadFailed', loadFailedEvent);

        this.dispatchEvent(loadFailedEvent);
    };

    private readonly onPictureInPictureStart = (): void => {
        this.playerLogger.debug('onPictureInPictureStart');
    };

    private readonly onPictureInPictureStop = (): void => {
        this.playerLogger.debug('onPictureInPictureStop');
    };

    private readonly onPictureInPictureRequestFailed = (): void => {
        this.playerLogger.debug('onPictureInPictureRequestFailed');
    };

    private readonly onPictureInPictureStopFailed = (): void => {
        this.playerLogger.debug('onPictureInPictureStopFailed');
    };

    private readonly onExternalPlayerLoad = (): void => {
        this.playerLogger.debug('event::onExternalPlayerLoad');

        if (!this.shouldAutoPlay) {
            return;
        }

        /**
         * This is a workaround for the DASH playback issue described below:
         *
         * There are cases when small 'gaps' are observed between periods.
         * These 'gaps', in turn, could lead to 'gaps' between buffered time ranges (check this.videoElement.buffered).
         * If shakaPlayer.load() method is called with 'startTime' value which is right inside of such 'gap',
         * then the player is stuck with only 'seeking' event generated as shaka player could not handle the jump.
         * Setting position manually after shakaPlayer.load() is finished helps to prevent this issue from happening.
         *
         * @see https://github.com/shaka-project/shaka-player/issues/2247
         * @see https://github.com/shaka-project/shaka-player/issues/2990
         */
        if (!this.isLive.currentValue && this.startTime !== null) {
            this.setPosition(this.startTime, { manual: false });
        }

        this.play();
    };

    private readonly onExternalPlayerUnload = (): void => {
        const stoppedStateEvent = new StoppedStatePlayerEvent();

        this.playerLogger.debug('event::onExternalPlayerUnload', stoppedStateEvent);

        if (this.forceVideoElementCreationOnLoad) {
            this.videoElement?.remove();
        }
    };

    private readonly onExternalPlayerUnloadFailed = (): void => {
        const unloadFailedEvent = new UnLoadFailedPlayerErrorEvent();

        this.playerLogger.error('event::onExternalPlayerUnloadFailed', unloadFailedEvent);

        this.dispatchEvent(unloadFailedEvent);
    };

    public logOut(message: string): void {
        const logUpdateEvent = new PlayerLogUpdateEvent(message);

        this.dispatchEvent(logUpdateEvent);
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async restart({ shouldAutoPlay }: PlayerRestartInfo): Promise<void> {
        return this.load({
            url: this.url,
            manifest: this.manifest,
            startTime: this.videoTime,
            shouldAutoPlay: shouldAutoPlay,
        });
    }

    public load(loadInfo: PlayerLoadInfo): void {
        const pipInitialStateEvent = this.pictureInPictureEnabled()
            ? new PictureInPictureInactiveStateEvent()
            : new PictureInPictureUnavailableStateEvent();

        this.firstPlaybackStateHasBeenDispatched = false;

        this.dispatchEvent(pipInitialStateEvent);

        if (!this.isViperCheckPassed()) {
            return this.onExternalPlayerLoadFailed();
        }

        const { url, manifest = '', mediaPlaylist, startTime, shouldAutoPlay } = loadInfo;

        this.playerLogger.info('operation:load', url, startTime);
        this.bandwidthValue.reset();

        if (!url) {
            return this.dispatchEvent(new EmptyManifestUrlErrorEvent());
        }

        this.url = url;
        this.manifest = manifest;
        this.startTime = startTime ?? null;
        this.shouldAutoPlay = shouldAutoPlay;

        Promise.resolve()
            .then(() => this.stopPromise)
            .then(() => this.recreateVideoElement())
            .then(() => this.externalPlayer.load(this.forceHttpsStreaming(url), manifest, mediaPlaylist, startTime))
            .catch(this.onExternalPlayerLoadFailed);
    }

    private isViperCheckPassed(): boolean {
        if (!this.isViperEnabled) {
            return true;
        }

        const hasClient = getScriptByAttributeSelector('src*="viper.client"');
        const hasVendor = getScriptByAttributeSelector('src*="viper.vendors"');

        return !!hasClient && !!hasVendor;
    }

    private forceHttpsStreaming(url: string): string {
        if (!this.shouldForceHttpsStreaming.currentValue) {
            return url;
        }

        const wrapped = new URL(url);

        wrapped.protocol = 'https';

        return wrapped.toString();
    }

    public readonly play = (): void => {
        if (!this.videoElement) {
            return;
        }
        this.playerLogger.info('operation:play');
        // This is a workaround for  Safari to ensure
        // that position is properly updated after pause.
        // Otherwise, it is observed that  videoElement.currentTime value is wrong sometime while playing the video
        try {
            if (this.videoPauseTime !== 0 && !this.isLive.currentValue && this.videoElement.currentTime !== 0 &&
                this.browser == 'Safari') {
                this.videoElement.currentTime = this.videoPauseTime;
            }
        } catch (error) {
            this.playerLogger.info("this.videoElement.currentTime error", error);
        }
        this.videoElement.play().catch((error: Error) => {
            // This is a fix for https://jira.lgi.io/browse/FLUXUI-57405
            // The issue happens when shaka fails in the background before starting a new playback session
            // causing the video element not set up properly. With this fix we make sure to trigger the
            // recreateVideoElement method which will enable the video element set up properly
            if (isWebOs(this.targetOs) && error.message.includes('The element has no supported sources')) {
                this.videoElementHasBeenRecreated = false;
            }

            this.playerLogger.error('Play failed: ', error);
        });
    };

    public pause(): void {
        if (!this.videoElement) {
            return;
        }
        this.playerLogger.info('operation:pause');
        if (this.browser == 'Safari') {
            this.videoPauseTime = this.videoElement.currentTime;
        }
        this.videoElement.pause();

    }

    public async stop(): Promise<void> {
        this.playerLogger.info('operation:stop');

        if (this.stopPromise) {
            this.playerLogger.info('operation:stop is already in progress');

            return this.stopPromise;
        }

        this.stopPlayGapTimer();

        this.reviewBuffer = null;
        this.startTime = null;
        this.manifestType = '';
        this.isSeeking = false;
        this.isUserSeek = false;

        this.externalPlayer.clear();

        this.stopPromise = this.externalPlayer.unload(!(this.isAdPlayer && this.targetOs === OS.tizen))
            .then(this.onExternalPlayerUnload)
            .catch(this.onExternalPlayerUnloadFailed)
            .finally(() => {
                this.playerLogger.info('operation:stop has been finished');
                this.stopPromise = null;
            });

        return this.stopPromise;
    }

    public selectTextTrack(id: string, language: string, role?: string): void {
        const currentTextTracks = this.videoElement?.textTracks;

        this.playerLogger.info('operation:selectTextTrack', id, language, role, currentTextTracks);
        this.externalPlayer.selectTextLanguage(id, language, role, currentTextTracks);
    }

    public selectAudioTrack(id: string, language: string, role?: string): void {
        this.playerLogger.info('operation:selectAudioTrack', id, language, role);
        this.externalPlayer.selectAudioLanguage(id, language, role);
    }

    /**
     * value - desired time to play from
     * manual - indicator, that this is manual click (eg user clicks on timeline)
     */
    public setPosition(value: number, { manual = true } = {}): void {
        if (this.externalPlayer.shouldHandleStartPosition) {
            this.startTime = value;
            this.playerLogger.info('operation:setPosition:shouldHandleStartPosition', value);

            // This is a workaraund for HLS playback in Safari to ensure
            // that current time is not updated before 'canplay' event is fired.
            //
            // Otherwise, it is observed that the newly provided position is sometimes *added*
            // to the videoElement.currentTime instead of replacing it with the new value.
            return;
        }

        this.playerLogger.info('operation:setPosition', value);
        let time;

        if (this.isLive.currentValue) {
            const { start, end } = this.externalPlayer.seekRange;
            const actualValue = start + value;

            time = actualValue > end ? end : actualValue;
        } else {
            time = value;
        }

        if (manual) {
            this.isUserSeek = true;
        }

        if (this.videoElement) {
            this.videoElement.currentTime = time;
        }
    }

    public setVolume(value: number): void {
        this.playerLogger.info('operation:setVolume', value);

        if (this.videoElement) {
            this.videoElement.volume = value;
        }
    }

    public setConfiguration(config?: PlayerExternalConfiguration): void {
        this.playerLogger.info('operation:setConfiguration: ', config);

        this.isViperEnabled = config?.isViperEnabled ?? false;
        this.shouldForceHttpsStreaming.set(config?.shouldForceHttpsStreaming);
        this.subtitleFontVOD.set(config?.subtitleFontVOD);
        this.subtitleBackgroundVOD.set(config?.subtitleBackgroundVOD);
        this.audioDescriptionLanguages.set(config?.audioDescriptionLanguages);
        this.hardOfHearingSubtitleLanguages.set(config?.hardOfHearingSubtitleLanguages);
        this.isLive.set(config?.isLive);
        this.isVod.set(config?.isVod);
        this.externalPlayer.setStyles(this.isVod.currentValue, this.subtitleBackgroundVOD.currentValue, this.subtitleFontVOD.currentValue, this.isFontLoaded, this.playerLogger);
        this.externalPlayer.setIgnoredScteTypes(config?.ignoreScte);
        this.externalPlayer.setAdBreakType(this.getAdBreaksParsingType(config));
        this.externalPlayer.setShouldParsePreRoll(config?.shouldParsePreRoll ?? false);
        this.isFontLoaded = true;
        this.externalPlayer.setPersistentLicenseOptions({
            isPersistentLicenseEnabled: config?.licensePersistencyEnabled,
            renewLicenseBeforeExpiration: config?.renewLicenseBeforeExpiration,
            isLive: this.isLive.currentValue,
        });

        this.externalPlayer.setRestrictions({
            maxResolutionWidth: config?.maxResolutionWidth,
            maxResolutionHeight: config?.maxResolutionHeight,
            softFrameRate: config?.softFrameRate,
            hardFrameRate: config?.hardFrameRate,
        });
        this.externalPlayer.setAudioPreferences(config?.preferredAudioLang, config?.preferredAudioRole);
        this.externalPlayer.setDrmConfiguration({
            keySystemPriority: config?.keySystemPriority,
            forceKeySystem: config?.forceKeySystem,
        });
        this.externalPlayer.setDrmContentId(config?.drmContentId || '');
        this.externalPlayer.setStartOverUpdateInterval(config?.startOverUpdateInterval || 0);
        this.isDebugOverlayEnabled = config?.isDebugOverlayEnabled ?? false;
    }

    private getAdBreaksParsingType(config?: PlayerExternalConfiguration): AdBreaksParsingType {
        return config?.adBreakType || AdBreaksParsingType.none;
    }

    public requestPictureInPictureMode(): void {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.requestPictureInPicture()
            .then(this.onPictureInPictureStart)
            .catch(this.onPictureInPictureRequestFailed);
    }

    public exitPictureInPicture(): void {
        if (window.document.pictureInPictureElement) {
            window.document.exitPictureInPicture()
                .then(this.onPictureInPictureStop)
                .catch(this.onPictureInPictureStopFailed);
        }
    }

    public pictureInPictureEnabled(): boolean {
        if (!this.videoElement) {
            return false;
        }

        return 'pictureInPictureEnabled' in window.document &&
            !this.videoElement.disablePictureInPicture;
    }

    public cleanPersistentLicense(): void {
        this.externalPlayer.cleanPersistentLicense();
    }

    private shouldRecreateVideoElement(): boolean {
        if (this.forceVideoElementCreationOnLoad) {
            return true;
        }

        //This is workaround for WebOS. We need to recreate player at first start. It's
        //because of Ad player and WebOS browser
        if (isWebOs(this.targetOs) && !this.videoElementHasBeenRecreated) {
            return true;
        }

        return this.isVideoElementRecreationRequired;
    }

    private async recreateVideoElement(): Promise<void> {
        if (!this.shouldRecreateVideoElement()) {
            this.playerLogger.info('operation:recreateVideoElement Recreation is not required since going to background during playback was not detected.');

            return;
        }

        this.videoElementHasBeenRecreated = true;

        this.playerLogger.info('operation:recreateVideoElement is starting...');

        this.isVideoElementRecreationRequired = false;

        await this.externalPlayer.unload();

        this.videoElement?.remove();
        const newVideoElement = document.createElement('video');

        await this.externalPlayer.useMediaElement(newVideoElement);

        this.videoContainer.append(newVideoElement);
        this.videoElement = newVideoElement;

        applyStyles(this.videoElement);

        this.registerVideoElementEvents();

        this.playerLogger.info('operation:recreateVideoElement is finished');
    }

    private readonly onVisibilityChanged = (): void => {
        this.playerLogger.info('event:visibilitychange', document.hidden);

        if (document.hidden) {
            this.isVideoElementRecreationRequired = true;
        }
    };

    private readonly onResponseHeaderUpdate = (): void => {
        if (this.responseHeader == this.externalPlayer.manifestResponseheader) {
            return;
        }

        this.responseHeader = this.externalPlayer.manifestResponseheader;

        const manifestResponseUpdateEvent = new PlayerManifestResponseHeadersUpdate(this.responseHeader);

        this.playerLogger.debug('event::manifestResponseUpdateEvent', manifestResponseUpdateEvent);

        this.dispatchEvent(manifestResponseUpdateEvent);

    };
}
