import { AdErrorCode } from '../consts/error';
import { PlayerEvent } from '../events/playerEvent';

export enum ExternalPlayerEventType {
  Buffering = 'buffering',
  TextChanged = 'textchanged',
  VariantChanged = 'variantchanged',
  ManifestParsed = 'manifestparsed',
  Error = 'error',
  RecoveryError = 'recoveryerror',
  ManifestTypeChanged = 'manifesttypechanged',
  Loaded = 'loaded',
  AdError = 'aderror',
  LicenseRenewalNeeded = 'licenserenewalneeded',
  TrackChanged = 'trackchanged',
  DurationChanged = 'durationchanged',
  ManifestResponseHeadersUpdate = 'manifestresponseheadersupdate',
}

export class ExternalPlayerBufferingEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.Buffering);
  }
}

export class ExternalPlayerTextChangedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.TextChanged);
  }
}

export class ExternalPlayerVariantChangedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.VariantChanged);
  }
}

export class ExternalPlayerTrackChangedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.TrackChanged);
  }
}

export class ExternalPlayerManifestParsedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.ManifestParsed);
  }
}

export class ExternalPlayerErrorEvent extends PlayerEvent {
  constructor(
    public readonly code: number,
    public readonly data: unknown,
    public readonly name: string,
    public readonly httpCode: number | null,
    public readonly isStreamingError: boolean
  ) {
    super(ExternalPlayerEventType.Error);
  }
}

export class ExternalPlayerRecoveryErrorEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.RecoveryError);
  }
}

export class ExternalPlayerAdErrorEvent extends PlayerEvent {
  constructor(public readonly code: AdErrorCode, public readonly manifestUrl: string) {
    super(ExternalPlayerEventType.AdError);
  }
}

export class ExternalPlayerManifestTypeChangedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.ManifestTypeChanged);
  }
}

export class ExternalPlayerLoaded extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.Loaded);
  }
}

export class ExternalLicenseRenewalNeeded extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.LicenseRenewalNeeded);
  }
}

export class ExternalPlayerDurationChangedEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.DurationChanged);
  }
}

export class ExternalPlayerManifestHeaderResponseEvent extends PlayerEvent {
  constructor() {
    super(ExternalPlayerEventType.ManifestResponseHeadersUpdate);
  }
}