import { StreamingProtocol } from '../../../consts/protocol';
import { PlayerEventTarget } from '../../../events/playerEvent';
import { PlayerAdBreak, PlayerScteSegment } from '../../../shared';
import ExternalPlayer, { ExternalPlayerBufferedInfo, ExternalPlayerStatistics, ExternalPlayerTrack } from '../../externalPlayer';
import { ExternalPlayerEventType } from '../../externalPlayerEvent';

export default class ShakaExternalPlayerFallback extends PlayerEventTarget<ExternalPlayerEventType> implements ExternalPlayer {
    cleanPersistentLicense(): void {
        // empty
    }
    setPersistentLicenseOptions(): void {
        // empty
    }
    load(): Promise<void> {
        return Promise.reject();
    }

    unload(): Promise<void> {
        return Promise.resolve();
    }

    clear(): void {
        // empty
    }

    getDiagnosticInfo(): string {
        return '';
    }

    getStats(): ExternalPlayerStatistics {
        return {
            streamBandwidth: 0,
            droppedFrames: 0,
        };
    }

    getBufferedInfo(): ExternalPlayerBufferedInfo {
        return { bufferLength: 0 };
    }

    getAudioTracks(): ExternalPlayerTrack[] {
        return [];
    }
    getTextTracks(): ExternalPlayerTrack[] {
        return [];
    }

    getCurrentFrameRate(): number | null {
        return null;
    }

    selectAudioLanguage(): void {
        //empty
    }

    selectTextLanguage(): void {
        //empty
    }

    setStyles(): void {
        //empty
    }

    setAdBreakType(): void {
        //empty
    }

    setIgnoredScteTypes(): void {
        //empty
    }

    setShouldParsePreRoll(): void {
        //empty
    }

    onLoadedData(): void {
        //empty
    }

    isHardOfHearingStandartWayOfSignalingAvailable(): boolean {
        return false;
    }

    isAudioDescriptionStandartWayOfSignalingAvailable(): boolean {
        return false;
    }

    getAdBreaks(): PlayerAdBreak[] {
        return [];
    }

    getScteSegments(): PlayerScteSegment[] {
        return [];
    }

    getLicenseExpiration(): number | null {
        return null;
    }

    setPlaybackStartState(): void {
        //empty
    }

    setDrmConfiguration(): void {
        //empty
    }

    setRestrictions(): void {
        // empty
    }

    setAudioPreferences(): void {
        // empty
    }

    setDrmContentId(): void {
        // empty
    }

    setStartOverUpdateInterval(): void {
        // empty
    }

    hasVideoBeenStarted(): boolean {
        return false;
    }

    useMediaElement(): Promise<void> {
        return Promise.resolve();
    }

    shouldWaitForCanPlayEventAfterSeek(): boolean {
        return false;
    }

    public isBuffering = false;

    public isPaused = false;

    public isBrowserSupported = false;

    public readonly protocol = StreamingProtocol.None;

    public securityLevel = '';

    public readonly reviewBuffer = 0;

    public readonly seekRange = { start: 0, end: 0 };

    public shouldHandleStartPosition = false;

    public readonly duration = 0;

    public readonly manifestType = '';

    public readonly  manifestResponseheader = '';
}
